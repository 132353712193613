<template>
  <b-modal
    v-model="visible"
    v-bind="{
      value,
      title,
      cancelTitle,
      centered,
      lazy,
      okTitle,
      cancelVariant,
      okVariant,
      okOnly,
      noStacking,
      scrollable,
      okDisabled,
      modalClass,
      hideFooter,
      hideHeader,
      size,
    }"
    @hide="onClose"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: 'CommonDialog',
  props: {
    okDisabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    title: {
      type: String,
      default() {
        return ''
      },
    },
    value: {
      type: Boolean,
      default() {
        return false
      },
    },
    centered: {
      type: Boolean,
      default() {
        return true
      },
    },
    lazy: {
      type: Boolean,
      default() {
        return true
      },
    },
    cancelTitle: {
      type: String,
      default() {
        return 'Отмена'
      },
    },
    okTitle: {
      type: String,
      default() {
        return 'Удалить'
      },
    },
    cancelVariant: {
      type: String,
      default() {
        return 'danger'
      },
    },
    okVariant: {
      type: String,
      default() {
        return 'success'
      },
    },
    okOnly: {
      type: Boolean,
      default() {
        return false
      },
    },
    noStacking: {
      type: Boolean,
      default() {
        return true
      },
    },
    scrollable: {
      type: Boolean,
      default() {
        return false
      },
    },
    hideHeader: {
      type: Boolean,
      default() {
        return false
      },
    },
    hideFooter: {
      type: Boolean,
      default() {
        return false
      },
    },
    modalClass: {
      type: String,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      visible: this.value,
    }
  },
  methods: {
    onClose({ trigger }) {
      this.visible = false

      if (trigger === 'ok') {
        this.$emit('confirm', { confirmed: true })
      } else {
        this.$emit('confirm', { confirmed: false })
      }
    },
  },
  watch: {
    value(value) {
      this.visible = value
    },
  },
}
</script>

<style lang="scss">
.modal .modal-header .close {
  display: block;
}
</style>

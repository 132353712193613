var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({on:{"hide":_vm.onClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},'b-modal',{
    value: _vm.value,
    title: _vm.title,
    cancelTitle: _vm.cancelTitle,
    centered: _vm.centered,
    lazy: _vm.lazy,
    okTitle: _vm.okTitle,
    cancelVariant: _vm.cancelVariant,
    okVariant: _vm.okVariant,
    okOnly: _vm.okOnly,
    noStacking: _vm.noStacking,
    scrollable: _vm.scrollable,
    okDisabled: _vm.okDisabled,
    modalClass: _vm.modalClass,
    hideFooter: _vm.hideFooter,
    hideHeader: _vm.hideHeader,
    size: _vm.size,
  },false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }